<template>
  <div class="login">
    <div class="from">
      <div class="title">轶哥博客</div>
      <div class="content">
        <el-input
          type="password"
          v-model="password"
          placeholder="密码"
          autofocus
          @keyup.enter="login"
        ></el-input>
        <div class="setting">
          <el-checkbox v-model="remember" checked class="remember"
            >当前设备免登陆</el-checkbox
          >
          <el-button
            type="primary"
            @click="login"
            class="put"
            :loading="btnLoading"
            >登录</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { setToken } from '../plugins/util'

export default {
  name: 'Login',
  data: function () {
    return {
      password: '',
      remember: true,
      btnLoading: false
    }
  },
  mounted: function () {
    window.document.onkeydown = (event) => {
      if (this.$route.path.includes('login')) {
        const e = event || window.event
        if (e && e.keyCode === 13) { // enter 键
          this.login()
        }
      }
    }

    // 根据参数判断提示
    switch (parseInt(this.$route.query.code)) {
      case 401:
        this.$message.error({ message: '没有权限或登录超时！', duration: 6000 })
        break
    }
  },
  methods: {
    async login () {
      if (this.password.trim() === '') {
        this.$message.warning({ message: '请输入密码。' })
      } else {
        this.btnLoading = true

        try {
          const { data } = await this.$request.post('/manage/user/login', {
            password: this.password
          })

          if (data.status === 1) {
            if (setToken('Bearer', 'Bearer ' + data.result, this.remember)) {
              this.$router.push({ name: 'Home' })
            } else {
              this.$message.error({ message: '请升级浏览器！' })
            }
          } else {
            this.$message.error({ message: '用户名或密码错误！' })
          }
        } catch (error) {
          this.$message.error({ message: error.message })
        }

        this.btnLoading = false
      }
    }
  }
}
</script>

<style lang="stylus" socped>
.login
  height 100%
  padding 80px 20px 20px 20px

  .from
    max-width 400px
    background-color #fff
    box-shadow 0 1px 3px rgba(0, 0, 0, 0.13)
    margin auto
    border-radius 6px

    .title
      color #505153
      text-align center
      font-weight 300
      font-size 1.8em
      margin 0
      padding 15px 0px
      background #f0f0f0
      border-top-left-radius 6px
      border-top-right-radius 6px

    .content
      padding 20px

    .setting
      margin-top 5px
      line-height 40px

      .remember
        color #505153

      .put
        display block
        width 100%
        margin-right 0
        padding 2px 50px
        line-height 34px
</style>
