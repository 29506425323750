import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Edit from './views/Edit.vue'
import Trems from './views/Trems.vue'
import Tags from './views/Tags.vue'
import Login from './views/Login.vue'
import Comment from './views/Comment.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [{
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/edit',
    name: 'Edit',
    component: Edit
  },
  {
    path: '/trems',
    name: 'Trems',
    component: Trems
  },
  {
    path: '/tags',
    name: 'Tags',
    component: Tags
  },
  {
    path: '/comment',
    name: 'Comment',
    component: Comment
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '*',
    component: {
      render () {
        return <h1>&nbsp;404</h1>
      }
    }
  }
  ]
})
