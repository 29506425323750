<template>
  <div class="controlCenter">
    <div class="contorlTop">
      <div class="pageTitle">所有评论</div>
    </div>
    <div class="articleTop">
      <div class="statistics">
        全部 ({{ comments.publishNum + comments.draftNum }}) | 已审核 ({{ comments.publishNum }})
        <div v-show="comments.draftNum">| 待审核 ({{ comments.draftNum }})</div>
        <div v-show="comments.trashNum">&nbsp;| 垃圾箱 ({{ comments.trashNum }})</div>
      </div>
      <div class="search">
        <el-input placeholder="搜索" v-model="searchInput">
          <el-select v-model="searchSelect" slot="prepend">
            <el-option label="内容" value="content"></el-option>
            <el-option label="作者" value="author"></el-option>
          </el-select>
          <el-button slot="append" icon="el-icon-search"></el-button>
        </el-input>
      </div>
    </div>
    <div class="articeControl">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="24" :lg="8">
          <el-select v-model="commentContorlValue" @change="handleArticleContorlChange" placeholder="批量操作">
            <el-option v-for="item in commenteContorlOptions" :label="item.label" :value="item.value" :key="item.id" :disabled="item.disabled"></el-option>
          </el-select>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="16">
          <div class="pageContorl">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="comments.currentPage"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="comments.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="comments.amount"
            ></el-pagination>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="postTable">
      <el-table
        :data="comments.list"
        style="width: 100%"
        v-loading="TableLoading"
        element-loading-text="拼命加载中"
        v-if="comments.publishNum + comments.draftNum"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column label="内容" min-width="400" sortable show-overflow-tooltip>
          <template slot-scope="scope">
            <div @click="openComment(scope.row)" v-html="markdownToHTML(scope.row.comment_content)"></div>
          </template>
        </el-table-column>
        <el-table-column prop="comment_author" label="作者"></el-table-column>
        <el-table-column label="日期" prop="comment_date" sortable width="190" :formatter="dateFilter"></el-table-column>
        <el-table-column label="文章" min-width="400" sortable show-overflow-tooltip>
          <template slot-scope="scope">
            <a :href="'https://www.wyr.me/post/' + scope.row.comment_post_ID" target="_blank">{{ scope.row.post.post_title }}</a>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import marked from 'marked'
import dayjs from 'dayjs'

const renderer = new marked.Renderer()

renderer.link = function (href, title, text) { // 在新页面中打开文章中的链接
  return '<a target="_blank" href="' + href + '" title="' + title + '" rel="external nofollow">' + text + '</a>'
}

export default {
  name: 'Comment',
  data () {
    return {
      comments: {},
      searchSelect: 'content',
      searchInput: null,
      commenteContorlOptions: [{
        id: 1,
        value: 'edit',
        label: '编辑',
        disabled: true
      }, {
        id: 2,
        value: 'delete',
        label: '移至垃圾箱'
      }],
      multipleSelection: [],
      commentContorlValue: '',
      TableLoading: false
    }
  },
  async created () {
    await this.getCommentList()
  },
  methods: {
    async getCommentList () {
      // 获取文章列表数据
      try {
        const { data } = await this.$request.post('/manage/comments/list', {
          currentPage: 1,
          pageSize: 10
        })

        this.comments = data.result
      } catch (err) {
        if (err.toString().includes('Network Error')) {
          document.body.innerHTML = '<h2 style="text-align: center">Network Error</h2>'
          this.$notify({
            title: '提示',
            message: '网络异常，请刷新重试',
            duration: 0
          })
        }
      }
    },
    async updateData () {
      this.TableLoading = true
      let PostTable = null

      try {
        PostTable = await this.$request.post('/manage/comments/list', {
          currentPage: this.comments.currentPage,
          pageSize: this.comments.pageSize
        })
      } catch (err) {
        console.log(err)
      }

      this.comments = PostTable.data.result
      this.TableLoading = false
    },
    handleSelectionChange (val) {
      this.multipleSelection = val
    },
    async handleSizeChange (pageSize) {
      this.comments.pageSize = pageSize
      this.updateData()
    },
    async handleCurrentChange (currentPage) {
      this.comments.currentPage = currentPage
      this.updateData()
    },
    async handleArticleContorlChange (current) {
      if (current === 'delete') {
        const delIDs = []
        for (const n in this.multipleSelection) {
          delIDs.push(Number(this.multipleSelection[n].comment_ID))
        }
        try {
          const { data } = await this.$request.post('/manage/comments/delete', {
            IDs: delIDs
          })
          if (data.status === 1) {
            this.$message.info({ message: '标记成功' })
            this.getCommentList()
          } else {
            this.$message.error({ message: '标记发生错误！' })
          }
        } catch (err) {
          console.log(err)
          this.$message.error({ message: '标记发生错误，请刷新重试！' })
        }
      }

      this.commentContorlValue = ''
    },
    openComment (row) {
      window.open('https://www.wyr.me/post/' + row.comment_post_ID + '?hash=comment_ID_' + row.comment_ID)
    },
    dateFilter (row, column) {
      return dayjs(row.comment_date).format('YYYY-MM-DD HH:mm:ss')
    },
    markdownToHTML (value) {
      return marked(value, {
        renderer
      })
    }
  }
}
</script>

<style lang="stylus" socped>
a, a:focus, a:hover, a:visited
  color #41B883
  text-decoration none

.controlCenter
  .contorlTop
    margin-bottom 10px
    height 36px
    line-height 36px

    .pageTitle
      float left

    .addNew
      float right

.articleTop
  margin-bottom 10px
  height 36px
  line-height 36px

  .statistics
    float left
    font-size 9px

    div
      display inline

  .search
    float right
    width 260px

    .el-select .el-input
      width 80px

.articeControl
  margin-bottom 10px
  height 36px
  line-height 36px

  .pageContorl
    float right

@media (max-width: 768px)
  .articleTop
    .search
      width 100%

  .articeControl
    margin-top 46px
    margin-bottom 10px
    height 36px
    line-height 36px

    .el-pagination__total, .el-pagination__sizes, .el-pagination__jump
      text-align right
      margin-top 10px
      height 36px
      line-height 36px
      display block
</style>
