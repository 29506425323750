<template>
  <div class="controlCenter">
    <div class="contorlTop">
      <div class="pageTitle">{{ pageTitle }}</div>
    </div>

    <div class="articleInputTitle">
      <el-input placeholder="请输入标题" v-model="articleTitle"></el-input>
    </div>

    <el-collapse v-model="activeNames" style="margin-bottom: 10px">
      <el-collapse-item title="文章摘要" name="excerpt">
        <div id="editor-excerpt"></div>
      </el-collapse-item>
      <el-collapse-item title="上传文件" name="file">
        <el-upload drag name="file" class="upload" :action="uploadURL" :headers="uplaodHeader" :on-preview="onClickFile" multiple>
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            将文件拖到此处，或
            <em>点击上传</em>
          </div>
          <div class="el-upload__tip" slot="tip">不超过500Mb</div>
        </el-upload>
      </el-collapse-item>
    </el-collapse>

    <div id="editor-article"></div>

    <div class="articleContors">
      <el-row :gutter="20" style="margin-left:0; margin-right:0;">
        <div class="termsOptions">
          <el-col :xs="24" :sm="24" :md="12" :lg="12" style="display: inline;">
            <div class="termTitle">分类：</div>
            <el-select v-model="terms" multiple filterable allow-create :filter-method="filterCategory" placeholder="请选择或输入分类">
              <el-option v-for="item in termsOptions" :key="item.id" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" class="tags">
            <div class="termTitle">标签：</div>
            <el-select v-model="tags" multiple filterable allow-create placeholder="请选择或输入标签">
              <el-option v-for="item in tagsOptions" :label="item.label" :value="item.value" :key="item.id"></el-option>
            </el-select>
          </el-col>
        </div>
        <div class="postControl">
          <el-button @click.stop="SaveArticle('draft')">保存草稿</el-button>
          <el-button type="primary" v-on:click.stop="SaveArticle">{{ SaveArticleTitle }}</el-button>
        </div>
      </el-row>
    </div>
  </div>
</template>

<script>
import { API_BASE_URL } from '../config'
import { getToken } from '../plugins/util.js'
import copy from 'copy-to-clipboard'

export default {
  name: 'Edit',
  data () {
    return {
      PostID: this.$route.query.ID,
      articleTitle: '',
      activeNames: [],
      articleExcerpt: '',
      articleContent: '',
      termsOptions: null,
      tagsOptions: null,
      terms: [],
      tags: [],
      uploadURL: API_BASE_URL + '/file/upload',
      uplaodHeader: {
        Authorization: getToken('Bearer')
      }
    }
  },
  computed: {
    pageTitle () {
      return this.PostID ? '编辑文章' : '写文章'
    },
    SaveArticleTitle () {
      return this.PostID ? '保存' : '发布'
    }
  },
  async mounted () {
    let terms = null

    try {
      terms = await Promise.all([
        this.$request.post('/manage/terms/getAll', { taxonomy: 'category' }),
        this.$request.post('/manage/terms/getAll', { taxonomy: 'post_tag' })
      ])
    } catch (err) {
      console.log(err)
    }

    const categoryTmp = []

    for (const n in terms[0].data.result) {
      if (terms[0].data.result[n].term_taxonomy.parent !== '0') {
        terms[0].data.result[n].name = ' - ' + terms[0].data.result[n].name
      }
      categoryTmp.push({
        id: n,
        value: terms[0].data.result[n].name,
        label: terms[0].data.result[n].name
      })
    }

    const tagsTmp = []

    for (const n in terms[1].data.result) {
      tagsTmp.push({
        id: n,
        value: terms[1].data.result[n].name,
        label: terms[1].data.result[n].name
      })
    }

    let PostDetails = null
    const PostTerms = []
    const PostTags = []
    try {
      if (this.PostID) {
        PostDetails = await this.$request.post('/manage/article/details', { ID: this.PostID })
        if (PostDetails.data.status === 1) {
          PostDetails = PostDetails.data.result
          for (const n in PostDetails.term_relationships) {
            if (PostDetails.term_relationships[n].term_taxonomy.taxonomy === 'category') {
              PostTerms.push(PostDetails.term_relationships[n].term_taxonomy.term.name)
            } else {
              PostTags.push(PostDetails.term_relationships[n].term_taxonomy.term.name)
            }
          }
        } else {
          PostDetails = null
        }
      }
    } catch (e) {
      console.log(e)
    }

    this.articleTitle = PostDetails ? PostDetails.post_title : ''
    this.articleExcerpt = PostDetails ? PostDetails.post_excerpt : ''
    this.articleContent = PostDetails ? PostDetails.post_content : ''
    this.termsOptions = categoryTmp
    this.tagsOptions = tagsTmp
    this.terms = PostTerms || []
    this.tags = PostTags || []

    this.initEditor()
  },
  methods: {
    initEditor () {
      const upload = {
        accept: 'image/*, video/*, audio/*, text/*, application/*, .rar, .zip, .php, .pptx, .ppt, .doc, .docx, .txt, .xls, .xlsx',
        url: API_BASE_URL + '/manage/file/upload',
        max: 10485760, // 10 MB
        linkToImgUrl: API_BASE_URL + '/manage/file/fetch',
        headers: {
          Authorization: getToken('Bearer')
        },
        filename: name => encodeURIComponent(name.replace(/[^(a-zA-Z0-9\u4E00-\u9FA5\.)]/g, '') // eslint-disable-line
          .replace(/[\?\\/:|<>\*\[\]\(\)\$%\{\}@~]/g, '') // eslint-disable-line
          .replace('/\\s/g', '')),
        format (files, responseText) {
          return responseText
        },
        error: (err) => {
          console.log(err)
          this.$message.error({ message: '上传失败' })
        }
      }

      let toolbar
      if (window.innerWidth < 768) {
        toolbar = [
          'emoji',
          'headings',
          'bold',
          'italic',
          'strike',
          'link',
          '|',
          'list',
          'ordered-list',
          'check',
          'outdent',
          'indent',
          '|',
          'quote',
          'line',
          'code',
          'inline-code',
          'insert-before',
          'insert-after',
          '|',
          'upload',
          'record',
          'table',
          '|',
          'undo',
          'redo',
          '|',
          'edit-mode',
          'content-theme',
          'code-theme',
          'export',
          {
            name: 'more',
            toolbar: [
              'fullscreen',
              'both',
              'preview',
              'info'
            ]
          }
        ]
      }

      window.editorExcerptObj = new window.Vditor('editor-excerpt', {
        toolbar,
        minHeight: 220,
        lang: 'zh_CN',
        toolbarConfig: {
          pin: true
        },
        cache: {
          enable: false,
          id: 'editor-excerpt'
        },
        upload,
        placeholder: '我们书写的不是代码，而是人生',
        value: this.articleExcerpt
      })

      window.editorObj = new window.Vditor('editor-article', {
        toolbar,
        lang: 'zh_CN',
        minHeight: 440,
        toolbarConfig: {
          pin: true
        },
        cache: {
          enable: false,
          id: 'editor-article'
        },
        upload,
        preview: {
          markdown: {
            toc: true
          }
        },
        counter: {
          enable: true,
          type: 'text'
        },
        placeholder: '我们书写的不是代码，而是人生',
        mode: 'ir',
        outline: true,
        value: this.articleContent
      })
    },
    async SaveArticle (type) {
      const postData = {
        articleTitle: this.articleTitle,
        articleExcerpt: window.editorExcerptObj.getValue(),
        articleContent: window.editorObj.getValue(),
        terms: this.terms,
        tags: this.tags,
        draft: type === 'draft'
      }
      if (!this.PostID) {
        try {
          const result = await this.$request.post('/manage/article/add', postData)
          if (result.data.status === 1) {
            this.$message.info({ message: '添加文章成功！' })
            this.PostID = result.data.result.ID
          } else {
            console.log(result)
          }
        } catch (err) {
          console.log(err)
          this.$message.error({ message: '添加文章失败！请重试' })
        }
      } else {
        try {
          postData.PostID = this.PostID
          const result = await this.$request.post('/manage/article/save', postData)
          if (result.data.status === 1) {
            this.$message.info({ message: '保存文章成功！' })
          } else {
            console.log(result)
          }
        } catch (err) {
          console.log(err)
          this.$message.error({ message: '保存文章失败！请重试' })
        }
      }
    },
    filterCategory (nowValue) {
      return nowValue
    },
    onClickFile (file) {
      copy(file.response.result.urls[0])
    }
  }
}
</script>

<style lang="stylus" socped>
.controlCenter
  .contorlTop
    margin-bottom 10px
    height 36px
    line-height 36px

    .pageTitle
      float left

  .articleInputTitle
    width 100%
    margin-bottom 10px

  .articleContors
    margin-top 10px
    height 100%
    position relative

    .el-select
      display block
      margin-left 50px

    .termTitle
      float left
      line-height 36px

  .termsOptions
    width auto
    margin-right 168px

  .postControl
    text-align right
    position absolute
    bottom 0
    right 0
    vertical-align bottom

.editor-preview-side img
  width 100%
  height auto

@media (max-width 992px)
  .articleContors
    .tags
      margin-top 10px
</style>
