import axios from 'axios'
import { API_BASE_URL } from '../config'
import { getToken } from './util'

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    common: {
      Authorization: getToken('Bearer')
    }
  }
})

axiosInstance.interceptors.request.use(
  config => {
    if (getToken('Bearer')) {
      config.headers.Authorization = getToken('Bearer')
    }
    return config
  },
  error => {
    console.log(error)
    Promise.reject(error)
  }
)

export default axiosInstance
