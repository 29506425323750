<template>
  <div id="app">
    <el-container>
      <el-aside :width="isCollapse ? '65px' : '200px'">
        <el-menu
          :default-active="defaultActive"
          class="el-menu-vertical"
          :router="true"
          :collapse="isCollapse"
        >
          <el-menu-item index="/">
            <i class="el-icon-document"></i>
            <span slot="title">所有文章</span>
          </el-menu-item>
          <el-menu-item index="/edit">
            <i class="el-icon-edit"></i>
            <span slot="title">写文章</span>
          </el-menu-item>
          <el-menu-item index="/trems">
            <i class="el-icon-news"></i>
            <span slot="title">分类目录</span>
          </el-menu-item>
          <el-menu-item index="/tags">
            <i class="el-icon-star-off"></i>
            <span slot="title">标签</span>
          </el-menu-item>
          <el-menu-item index="/comment">
            <i class="el-icon-chat-line-square"></i>
            <span slot="title">评论</span>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-container>
        <el-header>
          <el-radio-group v-model="isCollapse" style="margin-top: 20px">
            <el-radio-button :label="false">展开</el-radio-button>
            <el-radio-button :label="true">收起</el-radio-button>
          </el-radio-group>

          <!-- 退出登录 -->
          <div class="right-menu">
            <el-button @click="logout">注销</el-button>
          </div>
        </el-header>
        <el-main>
          <router-view />
        </el-main>
        <el-footer height="25px">
          <div class="footer">轶哥博客</div>
        </el-footer>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import { delToken } from './plugins/util.js'

export default {
  name: 'app',
  data () {
    return {
      isCollapse: false,
      defaultActive: null
    }
  },
  created () {
    this.defaultActive = this.$route.path
  },
  async mounted () {
    await this.checkUserLoginStatus()
  },
  methods: {
    async checkUserLoginStatus () {
      const { data } = await this.$request.get('/manage/user/auth')
      if ((!data || data.status !== 1) && this.$route.name !== 'Login') { // 判断是否已经登录且前往的页面不是登录页
        console.log('登录状态失效')
        delToken('Bearer')
        this.$router.push({ name: 'Login' })
      }
      console.log(1234)
    },
    logout () {
      try {
        delToken('Bearer')
        // const { data } = await this.$request.post('/public/auth/logout')
        // if (data.status === 1) {
        this.$router.push({ name: 'Login' })
        // } else {
        //   this.$message.warning({
        //     message: '注销失败！请重试。'
        //   })
        // }
      } catch (error) {
        this.$message.error({
          message: `${error.response.status} 发生错误，请重试！`
        })
      }
    }
  }
}
</script>

<style lang="stylus">
html, body, #app
  width 100%
  height 100%
  margin 0

#app
  .el-container, .el-menu-vertical
    height 100%

  .right-menu
    float right
    margin-top 20px

  .footer
    width 100%
    text-align center
    font-size 10px
    color #e4e4e4
    height 17px
    line-height 17px

.el-main
  padding 0 20px 20px 20px !important

.vditor-toolbar--pin
  border-top 1px solid #d1d5da
  box-sizing border-box
  margin-top -1px
</style>
