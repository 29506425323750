// 获取Token
export const getToken = (name) => {
  if (window.sessionStorage && window.sessionStorage.getItem(name)) {
    return window.sessionStorage.getItem(name)
  } else if (window.localStorage && window.localStorage.getItem(name)) {
    return window.localStorage.getItem(name)
  } else {
    return null
  }
}

// 设置Token
export const setToken = (name, token, remember) => {
  if (window.sessionStorage) {
    window.sessionStorage.setItem(name, token)
  }

  if (remember && window.localStorage) {
    window.localStorage.setItem(name, token)
    return true
  }

  return false
}

// 删除Token
export const delToken = (name) => {
  if (window.sessionStorage) window.sessionStorage.removeItem(name)
  if (window.localStorage) window.localStorage.removeItem(name)
  return true
}

// 获取描述对象的值
export const getObjectValue = (obj, des) => {
  return eval('obj.' + des) // eslint-disable-line
}

/**
 * 将json按一定规律组合成父子模式
 * @param  {[type]} data                        json数据
 * @param  {Number} [minPid=0]                  最大的父ID（可能不是0，最大的是几就填几，就从几开始排）
 * @param  {String} [IDString='ID']             ID的别名
 * @param  {String} [pidString='pid']           父ID的别名
 * @param  {String} [childrenString='children'] 生成的子的别名（子的数据就在这个名称下）
 * @return {[type]}                             父子JSON
 */
export const toTreeData = (data, minPid = 0, IDString = 'ID', pidString = 'pid', childrenString = 'children') => {
  const pos = {}
  const tree = []
  let n = 0

  while (data.length !== 0) {
    if (getObjectValue(data[n], pidString) == minPid) { // eslint-disable-line
      // delete getObjectValue(data[n], pidString)
      data[n].children = []
      tree.push(data[n])
      pos[getObjectValue(data[n], IDString)] = [tree.length - 1]
      data.splice(n, 1)
      n--
    } else {
      const posArray = pos[getObjectValue(data[n], pidString)]
      if (posArray !== undefined) {
        let obj = tree[posArray[0]]
        for (let j = 1; j < posArray.length; j++) {
          obj = obj.children[posArray[j]]
        }
        // delete getObjectValue(data[n], pidString)
        data[n].children = []
        obj.children.push(data[n])
        pos[getObjectValue(data[n], IDString)] = posArray.concat([obj.children.length - 1])
        data.splice(n, 1)
        n--
      }
    }
    n++
    if (n > data.length - 1) {
      n = 0
    }
  }
  return tree
}
