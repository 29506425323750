<template>
  <div class="controlCenter">
    <div class="contorlTop">
      <div class="pageTitle">
        标签
      </div>
    </div>

    <el-row :gutter="20"
            class="NewTerm">
      <el-col :xs="24"
              :sm="24"
              :md="8"
              :lg="9">
        <div class="addNewTermTitle">
          添加新标签
        </div>
        <el-form label-position="top"
                 label-width="80px"
                 :model="formLabelAlign">
          <el-form-item label="名称">
            <el-input v-model="formLabelAlign.name"></el-input>
            这将是它在站点上显示的名字。
          </el-form-item>
          <el-form-item label="别名">
            <el-input v-model="formLabelAlign.slug"
                      placeholder="留空则系统自动填写"></el-input>
            “别名”是在URL中使用的别称，它可以令URL更美观。通常使用小写，只能包含字母，数字和连字符（-）。
          </el-form-item>
        </el-form>
        <el-button type="primary"
                   @click.stop="AddTerms">添加新标签</el-button>
      </el-col>
      <el-col :xs="24"
              :sm="24"
              :md="16"
              :lg="15"
              style="margin-top: 45px;">
        <el-table :data="TermsTableData"
                  height="370"
                  border
                  style="width: 100%">
          <el-table-column prop="name"
                           label="名称"
                           min-width="180">
          </el-table-column>
          <el-table-column prop="slug"
                           label="别名"
                           width="180">
          </el-table-column>
          <el-table-column prop="term_taxonomy.count"
                           width="70"
                           label="总数">
          </el-table-column>
          <el-table-column label="操作"
                           width="160">
            <template slot-scope="scope">
              <el-button size="small"
                         @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
              <el-button size="small"
                         type="danger"
                         @click="handleDelete(scope.$index, scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="bottomInfo">注：删除一个标签不会删除相关的文章。标签可以有选择性地转换成分类目录，请使用标签到分类目录转换器（正开发）。</div>
      </el-col>
    </el-row>

    <el-dialog :visible.sync="modalShow"
               title="编辑"
               width="50%"
               center>
      <el-form ref="form"
               :model="editFormLabelAlign"
               label-width="80px">
        <div class="from-body">
          <el-form-item label="名称">
            <el-input v-model="editFormLabelAlign.name"></el-input>
          </el-form-item>
          <el-form-item label="别名">
            <el-input v-model="editFormLabelAlign.slug"
                      placeholder="只能包含字母，数字和连字符（-）">
              <el-button slot="append"
                         @click="slugAutogeneration">自动生成</el-button>
            </el-input>
          </el-form-item>
        </div>
      </el-form>
      <span class="dialog-footer"
            slot="footer">
        <el-button v-on:click.stop="modalShow = false">取消</el-button>
        <el-button type="primary"
                   v-on:click.stop="onOkHandler">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'Tags',
  data: function () {
    return {
      TermsTableData: null,
      formLabelAlign: {
        term_id: null,
        name: '',
        slug: '',
        taxonomy: 'post_tag'
      },
      editFormLabelAlign: {
        term_id: null,
        name: '',
        slug: ''
      },
      modalShow: false
    }
  },
  async mounted () {
    try {
      const { data } = await this.$request.post('/manage/terms/getAll', { taxonomy: 'post_tag' })

      this.TermsTableData = data.result
    } catch (err) {
      console.log(err)
      this.$message.error({ message: '错误：' + err })
    }
  },
  methods: {
    async AddTerms () {
      try {
        const result = await this.$request.post('/manage/terms/create', this.formLabelAlign)
        if (result.data.status === 1) {
          this.formLabelAlign.term_id = result.data.result.term_id
          this.formLabelAlign.term_taxonomy = {
            count: 0,
            taxonomy: 'post_tag'
          }
          this.formLabelAlign.slug = this.formLabelAlign.slug || encodeURI(this.formLabelAlign.name)
          this.TermsTableData.push(this.formLabelAlign)
          this.$message.info({ message: '添加成功！' })
          this.formLabelAlign = {
            term_id: null,
            name: '',
            slug: '',
            taxonomy: 'post_tag'
          }
        } else if (result.data.status === 80) {
          this.$message.warning({ message: '添加的标签已存在，标签名和别名均不能重复' })
        } else {
          this.$message.warning({ message: '添加失败，请重试！' })
        }
      } catch (e) {
        console.log(e)
        this.$message.error({ message: e })
      }
    },
    handleEdit: function (index, row) {
      this.editFormLabelAlign.term_id = row.term_id
      this.editFormLabelAlign.name = row.name
      this.editFormLabelAlign.slug = row.slug
      this.modalShow = true
    },
    async handleDelete (index, row) {
      try {
        row.taxonomy = row.taxonomy || row.term_taxonomy.taxonomy
        const result = await this.$request.post('/manage/terms/delete', row)
        if (result.data.status === 1) {
          for (const n in this.TermsTableData) {
            if (this.TermsTableData[n].term_id === row.term_id) {
              this.TermsTableData.splice(n, 1)
              break
            }
          }
          this.$message.info({ message: '删除成功！' })
        } else {
          this.$message.warning({ message: '删除失败，请重试！' })
        }
      } catch (e) {
        console.log(e)
        this.$message.error({ message: e })
      }
    },
    async onOkHandler () {
      try {
        const result = await this.$request.post('/manage/terms/edit', this.editFormLabelAlign)
        if (result.data.status === 1) {
          for (const n in this.TermsTableData) {
            if (this.TermsTableData[n].term_id === this.editFormLabelAlign.term_id) {
              this.TermsTableData[n].name = this.editFormLabelAlign.name
              this.TermsTableData[n].slug = this.editFormLabelAlign.slug
              break
            }
          }
          this.$message.info({ message: '修改成功！' })
          this.modalShow = false
        } else {
          this.$message.warning({ message: '修改失败，请重试！' })
        }
      } catch (e) {
        console.log(e)
        this.$message.error({ message: e })
      }
    },
    slugAutogeneration: function () {
      this.editFormLabelAlign.slug = encodeURI(this.editFormLabelAlign.name)
    }
  }
}
</script>

<style lang="stylus" socped>
.controlCenter
  .contorlTop
    margin-bottom 10px
    height 36px
    line-height 36px

    .pageTitle
      float left

.NewTerm
  .el-input
    margin-bottom 5px

  .el-form-item__content, .bottomInfo
    font-size 9px
    line-height 1.5

  .addNewTermTitle
    margin-top 10px
    margin-bottom 15px

  .bottomInfo
    margin-top 10px

.from-body
  padding 25px 20px 10px 10px

.el-cascader
  display block
</style>
