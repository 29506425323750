<template>
  <div class="controlCenter">
    <div class="contorlTop">
      <div class="pageTitle">
        分类目录
      </div>
    </div>

    <el-row :gutter="20"
            class="NewTerm">
      <el-col :xs="24"
              :sm="24"
              :md="8"
              :lg="9">
        <div class="addNewTermTitle">
          添加新分类目录
        </div>
        <el-form label-position="top"
                 label-width="80px"
                 :model="formLabelAlign">
          <el-form-item label="名称">
            <el-input v-model="formLabelAlign.name"></el-input>
            这将是它在站点上显示的名字。
          </el-form-item>
          <el-form-item label="别名">
            <el-input v-model="formLabelAlign.slug"
                      placeholder="留空则系统自动填写"></el-input>
            “别名”是在URL中使用的别称，它可以令URL更美观。通常使用小写，只能包含字母，数字和连字符（-）。
          </el-form-item>
          <el-form-item label="父节点">
            <el-cascader :options="termsOptions"
                         change-on-select
                         v-model="formLabelAlign.parent"></el-cascader>
            分类目录和标签不同，它可以有层级关系。您可以有一个“音乐”分类目录，在这个目录下可以有叫做“流行”和“古典”的子目录。留空则为顶级分类。
          </el-form-item>
        </el-form>
        <el-button type="primary"
                   @click.stop="AddTerms">添加新分类目录</el-button>
      </el-col>
      <el-col :xs="24"
              :sm="24"
              :md="16"
              :lg="15"
              style="margin-top: 45px;">
        <el-table :data="TermsTableData"
                  height="370"
                  border
                  style="width: 100%">
          <el-table-column prop="name"
                           label="名称"
                           min-width="180">
          </el-table-column>
          <el-table-column prop="slug"
                           label="别名"
                           width="180">
          </el-table-column>
          <el-table-column prop="term_taxonomy.count"
                           width="70"
                           label="总数">
          </el-table-column>
          <el-table-column label="操作"
                           width="160">
            <template slot-scope="scope">
              <el-button size="small"
                         @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
              <el-button size="small"
                         type="danger"
                         @click="handleDelete(scope.$index, scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="bottomInfo">注：删除一个分类不会删除分类中的文章。然而，仅属于被删除分类的文章将被指定为未分类分类。</div>
      </el-col>
    </el-row>

    <el-dialog :visible.sync="modalShow"
               title="编辑"
               width="50%"
               center>
      <el-form ref="form"
               :model="editFormLabelAlign"
               label-width="80px">
        <div class="from-body">
          <el-form-item label="名称">
            <el-input v-model="editFormLabelAlign.name"></el-input>
          </el-form-item>
          <el-form-item label="别名">
            <el-input v-model="editFormLabelAlign.slug"
                      placeholder="只能包含字母，数字和连字符（-）">
              <el-button slot="append"
                         @click="slugAutogeneration">自动生成</el-button>
            </el-input>
          </el-form-item>
          <el-form-item label="父节点">
            <el-cascader :options="termsOptions"
                         change-on-select
                         v-model="editFormLabelAlign.parent"></el-cascader>
          </el-form-item>
        </div>
      </el-form>
      <span class="dialog-foot"
            slot="footer">
        <el-button v-on:click.stop=" modalShow = false">取消</el-button>
        <el-button type="primary"
                   v-on:click.stop="onOkHandler">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { toTreeData } from '../plugins/util'

export default {
  name: 'Trems',
  data () {
    return {
      termsOptions: [
        {
          label: null,
          value: null
        }
      ],
      TermsTableData: null,
      formLabelAlign: {
        term_id: null,
        name: '',
        slug: '',
        parent: [],
        taxonomy: 'category'
      },
      editFormLabelAlign: {
        term_id: null,
        name: '',
        slug: '',
        parent: []
      },
      modalShow: false
    }
  },
  async created () {
    try {
      const { data } = await this.$request.post('/manage/terms/getAll', { taxonomy: 'category' })

      this.TermsTableData = data.result

      const termsOptions = []
      const tmpData = _.cloneDeep(data.result)
      delete tmpData[0].term_group

      for (const n in tmpData) {
        tmpData[n].parent = parseInt(tmpData[n].term_taxonomy.parent)
      }

      const tmpTermsOptions = toTreeData(tmpData, 0, 'term_id', 'parent')

      for (const n in tmpTermsOptions) {
        if (tmpTermsOptions[n].children.length > 0) {
          const tmpChildren = []
          for (const i in tmpTermsOptions[n].children) {
            tmpChildren.push({ value: tmpTermsOptions[n].children[i].term_id, label: tmpTermsOptions[n].children[i].name })
          }
          termsOptions.push({
            value: tmpTermsOptions[n].term_id,
            label: tmpTermsOptions[n].name,
            children: tmpChildren
          })
        } else {
          termsOptions.push({ value: tmpTermsOptions[n].term_id, label: tmpTermsOptions[n].name })
        }
      }

      this.termsOptions = termsOptions

      this.TermsTableData = data.result
    } catch (err) {
      console.log(err)
      this.$message.error({ message: '错误：' + err })
    }
  },
  methods: {
    AddTerms: async function () {
      try {
        const result = await this.$request.post('/manage/terms/create', this.formLabelAlign)
        if (result.data.status === 1) {
          this.formLabelAlign.term_id = result.data.result.term_id
          this.formLabelAlign.term_taxonomy = {
            count: 0,
            parent: this.formLabelAlign.parent[0] || 1,
            taxonomy: 'category'
          }
          this.formLabelAlign.slug = this.formLabelAlign.slug || encodeURI(this.formLabelAlign.name)
          this.TermsTableData.push(this.formLabelAlign)
          this.$message.info({ message: '添加成功！' })
          this.formLabelAlign = {
            term_id: null,
            name: '',
            slug: '',
            parent: [],
            taxonomy: 'category'
          }
        } else if (result.data.status === 80) {
          this.$message.warning({ message: '添加的分类已存在，分类名和别名均不能重复' })
        } else {
          this.$message.warning({ message: '添加失败，请重试！' })
        }
      } catch (e) {
        console.log(e)
        this.$message.error({ message: e })
      }
    },
    handleEdit (index, row) {
      this.editFormLabelAlign.term_id = row.term_id
      this.editFormLabelAlign.name = row.name
      this.editFormLabelAlign.slug = row.slug
      this.editFormLabelAlign.parent = [row.term_taxonomy.parent]
      this.modalShow = true
    },
    async handleDelete (index, row) {
      try {
        row.taxonomy = row.term_taxonomy.taxonomy
        row.parent = row.term_taxonomy.parent === '0' ? '1' : row.term_taxonomy.parent
        const result = await this.$request.post('/manage/terms/delete', row)
        if (result.data.status === 1) {
          for (const n in this.TermsTableData) {
            if (this.TermsTableData[n].term_id === row.term_id) {
              this.TermsTableData.splice(n, 1)
              break
            }
          }
          this.$message.info({ message: '删除成功！' })
        } else {
          this.$message.warning({ message: '删除失败，请重试！' })
        }
      } catch (e) {
        console.log(e)
        this.$message.error({ message: '致命错误' })
      }
    },
    onOkHandler: async function () {
      try {
        const result = await this.$request.post('/manage/terms/edit', this.editFormLabelAlign)
        if (result.data.status === 1) {
          for (const n in this.TermsTableData) {
            if (this.TermsTableData[n].term_id === this.editFormLabelAlign.term_id) {
              this.TermsTableData[n].name = this.editFormLabelAlign.name
              this.TermsTableData[n].slug = this.editFormLabelAlign.slug
              this.TermsTableData[n].term_taxonomy.parent = [this.editFormLabelAlign.parent]
              break
            }
          }
          this.$message.info({ message: '修改成功！' })
          this.modalShow = false
        } else {
          this.$message.warning({ message: '修改失败，请重试！' })
        }
      } catch (e) {
        console.log(e)
        this.$message.error({ message: e })
      }
    },
    slugAutogeneration: function () {
      this.editFormLabelAlign.slug = encodeURI(this.editFormLabelAlign.name)
    }
  }
}
</script>

<style lang="stylus" socped>
.controlCenter
  .contorlTop
    margin-bottom 10px
    height 36px
    line-height 36px

    .pageTitle
      float left

.NewTerm
  .el-input
    margin-bottom 5px

  .el-form-item__content, .bottomInfo
    font-size 9px
    line-height 1.5

  .addNewTermTitle
    margin-top 10px
    margin-bottom 15px

  .bottomInfo
    margin-top 10px

.from-body
  padding 25px 20px 10px 10px

.el-cascader
  display block
</style>
