<template>
  <div class="controlCenter">
    <div class="contorlTop">
      <div class="pageTitle">所有文章</div>
      <el-button type="primary" class="addNew" v-on:click="addNewPost">新增</el-button>
    </div>
    <div class="articleTop">
      <div class="statistics">
        全部 ({{ Articels.posts_publish + Articels.posts_draft }}) | 已发布 ({{ Articels.posts_publish }})
        <div v-show="Articels.posts_draft">| 草稿 ({{ Articels.posts_draft }})</div>
        <div v-show="Articels.posts_trash">| 回收站 ({{ Articels.posts_trash }})</div>
      </div>
      <div class="search">
        <el-input placeholder="搜索" v-model="searchInput">
          <el-select v-model="searchSelect" slot="prepend">
            <el-option label="内容" value="article"></el-option>
            <el-option label="标题" value="author"></el-option>
          </el-select>
          <el-button slot="append" icon="el-icon-search"></el-button>
        </el-input>
      </div>
    </div>
    <div class="articeControl">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="24" :lg="8">
          <el-select v-model="articleContorlValue" @change="handleArticleContorlChange" placeholder="批量操作">
            <el-option v-for="item in articleContorlOptions" :label="item.label" :value="item.value" :key="item.id" :disabled="item.disabled"></el-option>
          </el-select>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="16">
          <div class="pageContorl">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="Articels.current_page"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="Articels.page_size"
              layout="total, sizes, prev, pager, next, jumper"
              :total="Articels.posts_now_all"
            ></el-pagination>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="postTable">
      <el-table :data="Articels.posts" style="width: 100%" v-loading="TableLoading" element-loading-text="拼命加载中" v-if="Articels.posts.length" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column label="标题" min-width="400" sortable show-overflow-tooltip>
          <template slot-scope="scope">
            <router-link :to="'/edit?ID=' + scope.row.ID">{{ scope.row.post_title + (scope.row.post_status === 'draft' ? '(草稿)' : '') }}</router-link>
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="display_name"
          label="作者">
        </el-table-column>-->
        <el-table-column prop="post_category" min-width="100" label="分类目录"></el-table-column>
        <el-table-column prop="post_tag" width="150" label="文章标签"></el-table-column>
        <el-table-column prop="comment_count" sortable align="center" width="100" label="评论"></el-table-column>
        <el-table-column label="日期" prop="post_date" sortable width="190"></el-table-column>
        <el-table-column label="操作" sortable width="100">
          <template slot-scope="scope">
            <a :href="origin + '/post/' + scope.row.ID + (scope.row.post_status === 'draft' ? '?draft=true' : '')" target="_blank">浏览</a>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data () {
    return {
      Articels: {
        posts: [],
        posts_publish: null,
        posts_draft: null,
        posts_trash: null,
        posts_page_all: null,
        posts_now_all: null,
        posts_terms: [],
        posts_date_group: [],
        posts_all_terms: [],
        current_page: null,
        page_size: null
      },
      searchSelect: 'article',
      searchInput: null,
      articleContorlOptions: [{
        id: 1,
        value: 'edit',
        label: '编辑',
        disabled: true
      }, {
        id: 2,
        value: 'delete',
        label: '移至回收站'
      }],
      multipleSelection: [],
      articleContorlValue: '',
      TableLoading: false,
      origin: window.location.origin.includes('localhost') ? 'http://localhost:3000' : 'https://www.wyr.me'
    }
  },
  async created () {
    await this.getArticleList()
  },
  methods: {
    async getArticleList () {
      // 获取文章列表数据
      try {
        const { data } = await this.$request.post('/manage/article/list', {
          post_page: 1,
          post_status: 'all',
          term: 'all',
          page_size: 10
        })

        this.Articels = data
      } catch (err) {
        if (err.toString().includes('Network Error')) {
          document.body.innerHTML = '<h2 style="text-align: center">Network Error</h2>'
          this.$notify({
            title: '提示',
            message: '网络异常，请刷新重试',
            duration: 0
          })
        }
      }
    },
    async updateData () {
      this.TableLoading = true
      let PostTable = null

      try {
        PostTable = await this.$request.post('/manage/article/list', {
          post_page: this.Articels.current_page,
          post_status: 'publish',
          term: 'all',
          page_size: this.Articels.page_size
        })
      } catch (err) {
        console.log(err)
      }

      this.Articels = PostTable.data
      this.TableLoading = false
    },
    addNewPost () {
      this.$router.push('/edit')
    },
    handleSelectionChange (val) {
      this.multipleSelection = val
    },
    async handleSizeChange (pageSize) {
      this.Articels.page_size = pageSize
      this.updateData()
    },
    async handleCurrentChange (currentPage) {
      this.Articels.current_page = currentPage
      this.updateData()
    },
    async handleArticleContorlChange (current) {
      if (current === 'delete') {
        const delIDs = []
        for (const n in this.multipleSelection) {
          delIDs.push(this.multipleSelection[n].ID)
        }
        try {
          const { data } = await this.$request.post('/manage/article/delete', {
            IDs: delIDs
          })
          if (data.status === 1) {
            this.$message.info({ message: '删除成功！' })
            this.getArticleList()
          }
        } catch (err) {
          console.log(err)
          this.$message.error({ message: '删除发生错误，请刷新重试！' })
        }
      }

      this.articleContorlValue = ''
    },
    editArticle (current) {
      console.log(current)
    }
  }
}
</script>

<style lang="stylus" socped>
a, a:focus, a:hover, a:visited
  color #41B883
  text-decoration none

.controlCenter
  .contorlTop
    margin-bottom 10px
    height 36px
    line-height 36px

    .pageTitle
      float left

    .addNew
      float right

.articleTop
  margin-bottom 10px
  height 36px
  line-height 36px

  .statistics
    float left
    font-size 9px

    div
      display inline

  .search
    float right
    width 260px

    .el-select .el-input
      width 80px

.articeControl
  margin-bottom 10px
  height 36px
  line-height 36px

  .pageContorl
    float right

@media (max-width 768px)
  .articleTop
    .search
      width 100%

  .articeControl
    margin-top 46px
    margin-bottom 10px
    height 36px
    line-height 36px

    .el-pagination__total, .el-pagination__sizes, .el-pagination__jump
      text-align right
      margin-top 10px
      height 36px
      line-height 36px
      display block
</style>
