import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Element from 'element-ui'
import Request from './plugins/request'
import { getToken } from './plugins/util'
import _ from 'lodash'
import hljs from 'highlight.js'
import vditor from 'vditor'
import 'element-ui/lib/theme-chalk/index.css'
import 'highlight.js/styles/atom-one-light.css'
import 'vditor/dist/index.css'

Vue.use(Element, { size: 'small', zIndex: 3000 })

Vue.prototype.$request = Request
Vue.prototype._ = _
window._ = _
window.hljs = hljs

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  if (!getToken('Bearer') && to.name !== 'Login') { // 判断是否已经登录且前往的页面不是登录页
    next({
      name: 'Login'
    })
  } else if (getToken('Bearer') && to.name === 'Login') { // 判断是否已经登录且前往的是登录页
    next({
      name: 'Home'
    })
  } else {
    next()
  }
})

window.Vditor = vditor

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
